import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import why from "../../Assets/Projects/why.png";
import daedarus from "../../Assets/Projects/daedarus.png";
import klutcher from "../../Assets/Projects/klutcher.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={klutcher}
              isBlog={false}
              title="Klutcher Model Simulator"
              description="This is a simulation package for klucher model built on Python to calculate solar radiations on a tilted surface at a particular place for a whole year."
              link="https://pypi.org/project/KlucherModelSDas/1.0.1/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={why}
              isBlog={false}
              title="WHY Campaign Website"
              description="Website for the WHY Campaign led against domestic violence in Australia."
              link="https://www.thewhycampaign.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={daedarus}
              isBlog={false}
              title="Daedarus' Hack"
              description="Website for Daedarus' Hack with react.js and react bootstrap. Daedarus Hackathon was organized by the Microsoft Student Community, KIIT Chapter. It was the largest technical hackathon in our KIIT University."
              link="https://daedarus.co/"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Bits-0f-C0de"
              description="My personal blog page build with Next.js and Tailwind Css which takes the content from makdown files and renders it using Next.js. Supports dark mode and easy to write blogs using markdown."
              link="https://github.com/soumyajit4419/Bits-0f-C0de"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Ai For Social Good"
              description="Using 'Natural Launguage Processing' for the detection of suicide-related posts and user's suicide ideation in cyberspace  and thus helping in sucide prevention."
              link="https://github.com/soumyajit4419/AI_For_Social_Good"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              link="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
