import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import Resumecontent from "./ResumeContent";
import axios from "axios";
import pdf from "../../Assets/Resume.pdf";
import { AiOutlineDownload } from "react-icons/ai";

function Resume() {
  // const uri = "https://porfolio-backend.vercel.app/ranks/getRanks";
  // const [spojRank, upadteSpojRank] = useState(0);
  // const [hackerrank, upadteHackerank] = useState(0);
  // const [sem, upadateSem] = useState(0);
  // const [cgpa, upadteCgpa] = useState(0);

  // useEffect(() => {
  //   axios
  //     .get(uri)
  //     .then((res) => {
  //       upadteSpojRank(res.data.message[0].spojRank);
  //       upadteHackerank(res.data.message[1].hackerrank);
  //       upadteCgpa(res.data.message[2].cgpa);
  //       upadateSem(res.data.message[3].sem);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  return (
    <Container fluid className="resume-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
        <Row className="resume">
          <Col md={6} className="resume-left">
            <h3 className="resume-title">Experience</h3>
            <Resumecontent
              title="Technical Intern at AusInd Bridge Foundation"
              date="March 2021 - May 2021"
              content={[
                "Worked as a Technical Intern at AusInd Bridge Foundation where I learnt about the strong bonds shared by Australia and India and got the chance to work for The WHY Campaign established in Melbourne, Australia.",
              ]}
            />
            
            <Resumecontent
              title="Project Intern at Ansyst Consulting"
              date="June 2020 - Aug 2020"
              content={[
                "It is a website for the WHY Campaign in Australia which is for the movement against domestic violence. I have been working on this website under my Australia India Bridge Foundation Internship. ",
              ]}
            />
            <h3 className="resume-title">Extracurricular Activities</h3>
            <Resumecontent
              title="Technical Volunteer at The WHY Campaign"
              content={[
                "Created a website for the WHY Campaign in Australia which is for the movement against domestic violence. I have been working on this website under my Australia India Bridge Foundation Internship. ",
              ]}
            />
            <Resumecontent
              title="Lead Organizer of Daedarus' Hack"
              content={[
                "Worked on creating the website using React.js and React Bootstrap. Also, was one of the leads in conducting the whole hackathon on a international level.",
              ]}
            />
            <Resumecontent
              title="Web Development Instructor at KIIT Kaksha [Youtube]"
              content={[
                "Taught the basics of web development and the fundamentals of Node.js via course uploaded on the youtube channel.",
              ]}
            />
          </Col>
          <Col md={6} className="resume-right">
            <h3 className="resume-title">Education</h3>
            <Resumecontent
              title="B.Tech in Computer Science [KIIT University, Bhubaneshwar] "
              date="2019 - Present"
              content={[`CGPA: 9.47 (Till 5th Sem)`]}
            />
            <Resumecontent
              title="12TH BOARD [Delhi Public School,Gurgaon]"
              date="2018 - 2019"
              content={["Precentage: 84%"]}
            />
            <Resumecontent
              title="10TH BOARD [Delhi Public School,Gurgaon] "
              date="2016 - 2017"
              content={["CGPA: 9.8"]}
            />
            <h3 className="resume-title">Ranks and Achivements</h3>
            <Resumecontent
              title=""
              content={[
                "KIITEE Merit Scholarship for 2019 - 2023 [RANK 641]",
                "Gold Medal in 12th Class at DPS, Gurgaon",
                "Rank 199 in CPP by TechGig"
              ]}
            />
            <h3 className="resume-title">Organizations</h3>
            <Resumecontent
              title="Technical Lead at Microsoft Student Community, KIIT Chapter"
              date="Feb 2021 - Present"
              content={[
                "",
              ]}
            />
            <Resumecontent
              title="Content Creator at KIIT Kaksha [Youtube]"
              date="Feb 2021 - Present"
              content={[
                "",
              ]}
            />
            <Resumecontent
              title="Web Developer at Mozzila Club, Bhubaneshwar"
              date="Feb 2021 - Present"
              content={[
                "",
              ]}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
          <AiOutlineDownload />&nbsp;Download CV
          </Button>
        </Row>
      </Container>
    </Container>
  );
}

export default Resume;
