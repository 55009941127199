import React from 'react'
import { Helmet } from 'react-helmet'

const TITLE = 'Subhayan | Portfolio'

class Header extends React.PureComponent {
  render () {
    return (
      <>
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>
      </>
    )
  }
}

export default Header;
